import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'src/store';
import { selectedCompanyIdSelector } from 'src/store/selectors/companySelector';
import {
  schemeOptionsSelector,
  selectedSchemeIdSelector,
  isLoadingSchemeSelector,
} from 'src/store/selectors/schemeSelector';
import { filterStatusSelector } from 'src/store/selectors/filtersSelector';
import { selectScheme, fetchSchemeListByCompanyAction } from 'src/store/slices/schemeSlice';

import { getDashboardListBySchema } from 'src/api/dashboard/dashboard';
import { getQuickSightAnalysisListByDashboard } from 'src/api/dashboard/quickSight';

import { SelectOption, ServerFormErrors, CreateQuickSightDashboardModel, ServerError } from 'src/types';
import { selectOptionsFromDto } from 'src/utils/selectOptions';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import SelectOptions from 'src/components/layout/SelectOptions';
import styles from './Filters.module.scss';

type FilterProps = {
  getEmbedUrl: (model: CreateQuickSightDashboardModel) => Promise<void>;
  setServerErrorMessages: React.Dispatch<React.SetStateAction<ServerFormErrors>>;
};

function Filters({ getEmbedUrl, setServerErrorMessages }: FilterProps) {
  const dispatch = useDispatch();
  const filterStatus = useSelector(filterStatusSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const schemeOptions = useSelector(schemeOptionsSelector);
  const isLoadingScheme = useSelector(isLoadingSchemeSelector);
  const [dashboardId, setDashboardId] = useState('');
  const [quickSightAnalysisId, setQuickSightAnalysisId] = useState('');

  const [dashboardOptions, setDashboardOptions] = useState<SelectOption[] | undefined>([]);
  const [quickSightAnalysisOptions, setQuickSightAnalysisOptions] = useState<SelectOption[] | undefined>([]);

  const fillSchemeOptions = useCallback(async () => {
    if (!selectedCompanyId) return;
    setServerErrorMessages([]);
    try {
      dispatch(fetchSchemeListByCompanyAction(selectedCompanyId));
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(formErrors);
    }
  }, [dispatch, selectedCompanyId, setServerErrorMessages]);

  const fillDashboardOptions = useCallback(async () => {
    if (!selectedSchemeId || !schemeOptions.length) {
      setDashboardId('');
      setDashboardOptions([]);
      return;
    }

    setDashboardOptions(undefined);
    try {
      const { data } = await getDashboardListBySchema(selectedSchemeId);
      const options = selectOptionsFromDto(data);
      const mainDashboard = data.find((dashboard) => dashboard.isMain);
      const preselectedDashboardId = mainDashboard ? mainDashboard.id : (options[0]?.value ?? '');

      setDashboardOptions(options);
      setDashboardId(preselectedDashboardId);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(formErrors);
      setDashboardOptions([]);
      setDashboardId('');
    }
  }, [selectedSchemeId, schemeOptions.length, setServerErrorMessages]);

  const fillQuickSightAnalysisOptions = useCallback(async () => {
    if (!dashboardId) {
      setQuickSightAnalysisId('');
      setQuickSightAnalysisOptions([]);
      return;
    }
    setQuickSightAnalysisOptions(undefined);
    try {
      const { data } = await getQuickSightAnalysisListByDashboard(dashboardId, selectedSchemeId);
      const options = selectOptionsFromDto(data);
      const mainAnalysis = data.find((analysis) => analysis.isMain);
      const preselectedAnalysisId = mainAnalysis ? mainAnalysis.id : (options[0]?.value ?? '');
      setQuickSightAnalysisOptions(options);
      setQuickSightAnalysisId(preselectedAnalysisId);
    } catch (e) {
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(formErrors);
      setQuickSightAnalysisOptions([]);
      setQuickSightAnalysisId('');
    }
  }, [dashboardId, selectedSchemeId, setServerErrorMessages]);

  const setSchemaHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDashboardId('');
    dispatch(selectScheme(event.target.value));
  };
  const setDashboardHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setQuickSightAnalysisId('');
    setDashboardId(event.target.value);
  };
  const setQuickSightAnalysisHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setQuickSightAnalysisId(event.target.value);
  };

  useEffect(() => {
    setDashboardId('');
    fillSchemeOptions();
  }, [fillSchemeOptions]);

  useEffect(() => {
    fillDashboardOptions();
  }, [fillDashboardOptions]);

  useEffect(() => {
    fillQuickSightAnalysisOptions();
  }, [fillQuickSightAnalysisOptions]);

  useEffect(() => {
    getEmbedUrl({ schemeId: selectedSchemeId, dashboardId, quickSightAnalysisId });
  }, [getEmbedUrl, quickSightAnalysisId, selectedSchemeId, dashboardId]);

  return (
    <div
      className={classNames(styles.Filters, 'container is-flex-grow-0 px-2 is-fullwidth', {
        [styles.IsActive]: filterStatus,
      })}
    >
      <div className="control is-flex is-justify-content-center">
        <div className={classNames('select select-wrapper', { 'is-loading': isLoadingScheme })}>
          <select
            value={selectedSchemeId}
            disabled={schemeOptions === undefined || !selectedCompanyId || schemeOptions.length <= 1}
            onChange={setSchemaHandler}
            tabIndex={filterStatus ? 0 : -1}
            aria-label="Scheme name"
          >
            <option value="" disabled hidden>
              {schemeOptions && !schemeOptions.length ? 'No available schemes' : 'Scheme Name'}
            </option>
            <SelectOptions options={schemeOptions} />
          </select>
        </div>
        <div className={classNames('select select-wrapper', { 'is-loading': dashboardOptions === undefined })}>
          <select
            value={dashboardId}
            disabled={dashboardOptions === undefined || !selectedSchemeId}
            onChange={setDashboardHandler}
            tabIndex={filterStatus ? 0 : -1}
            aria-label="Dashboard name"
          >
            <option value="" disabled hidden>
              {dashboardOptions && !dashboardOptions.length ? 'No available dashboards' : 'Dashboard Name'}
            </option>
            <SelectOptions options={dashboardOptions} />
          </select>
        </div>
        <div className={classNames('select select-wrapper', { 'is-loading': quickSightAnalysisOptions === undefined })}>
          <select
            value={quickSightAnalysisId}
            disabled={quickSightAnalysisOptions === undefined || !dashboardId}
            onChange={setQuickSightAnalysisHandler}
            tabIndex={filterStatus ? 0 : -1}
            aria-label="QuickSight dashboard name"
          >
            <option value="" disabled hidden>
              {quickSightAnalysisOptions && !quickSightAnalysisOptions.length
                ? 'No available QS dashboards'
                : 'QS Dashboard Name'}
            </option>
            <SelectOptions options={quickSightAnalysisOptions} />
          </select>
        </div>
      </div>
    </div>
  );
}

export default Filters;
