import classNames from 'classnames';
import { Link, useMatch } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { isLoggedInSelector, authRoleSelector } from 'src/store/selectors/authSelector';
import {
  allCompanyOptionsSelector,
  selectedCompanyIdSelector,
  isLoadingCompanySelector,
} from 'src/store/selectors/companySelector';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
} from 'src/store/selectors/tenantBrandingSelector';
import { filterStatusSelector } from 'src/store/selectors/filtersSelector';
import { selectCompany } from 'src/store/slices/companySlice';
import { setFilterStatus } from 'src/store/slices/filterSlice';

import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';
import { LogoutIconSVG, MenuIcon, SettingIcon } from '@itm/shared-frontend/lib/components/icons';

import { logout } from 'src/hocs/AuthProvider';
import SelectOptions from 'src/components/layout/SelectOptions';

import { Role } from 'src/types';

import styles from './Header.module.scss';

function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userRole = useSelector(authRoleSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const isLoading = useSelector(isLoadingCompanySelector);
  const filterStatus = useSelector(filterStatusSelector);

  const dashboardRouteMatch = useMatch(RoutePath.dashboard);
  const dashboardAddRouteMatch = useMatch(RoutePath.administrationDashboardAdd);
  const dashboardProfileRouteMatch = useMatch(RoutePath.administrationDashboardProfile);

  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);

  return (
    <header className="main-header">
      <div className="container px-2">
        <nav className={classNames(styles.Nav, 'navbar px-3')} role="navigation" aria-label="main navigation">
          <div className="navbar-brand is-flex-grow-1 ml-0">
            <Link className="navbar-item mr-2" to={isLoggedIn ? RoutePath.root : RoutePath.loginRedirect}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>
            {!isLoading && !isLoggedIn ? null : (
              <>
                <div className="navbar-item is-flex-grow-1 is-justify-content-center">
                  <div className="control">
                    <div
                      className={classNames('select select-wrapper select-wrapper--header', {
                        'is-loading': isLoading,
                      })}
                    >
                      <select
                        value={selectedCompanyId}
                        disabled={
                          isLoading ||
                          !!dashboardProfileRouteMatch ||
                          !!dashboardAddRouteMatch ||
                          companyOptions.length <= 1
                        }
                        onChange={(e) => dispatch(selectCompany(e.target.value))}
                        aria-label="Company name"
                      >
                        <option value="" disabled hidden>
                          Company Name
                        </option>
                        <SelectOptions options={companyOptions} />
                      </select>
                    </div>
                  </div>
                </div>
                {dashboardRouteMatch && (
                  <div className={classNames(styles.FilterWrapper, 'is-flex is-align-items-center is-relative')}>
                    <label className="is-hidden-mobile is-clickable pr-3" htmlFor="filter">
                      Filter
                    </label>
                    <input
                      className={classNames(styles.FilterCheckbox)}
                      type="checkbox"
                      name="filter"
                      id="filter"
                      checked={filterStatus}
                      onChange={() => dispatch(setFilterStatus(!filterStatus))}
                    />
                    <span className={styles.FilterIconWrapper}>
                      <span className={styles.FilterIcon}></span>
                    </span>
                  </div>
                )}
                {(userRole === Role.SuperAdmin || userRole === Role.ClientAdmin) && (
                  <Link to={RoutePath.administrationRoot} className="navbar-item has-icon" aria-label="Settings">
                    <SettingIcon />
                  </Link>
                )}
                <a
                  href={clientPortalDashboardUrl}
                  className="navbar-item has-icon"
                  rel="noreferrer noopener"
                  aria-label="All products"
                >
                  <MenuIcon />
                </a>
                <button
                  className="button logout-button has-icon px-4"
                  type="button"
                  onClick={() => logout()}
                  aria-label="Logout"
                >
                  <LogoutIconSVG />
                </button>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
